import { RouteConfig } from 'vue-router'

export default [{
  path: '/waves',
  name: 'base-management.waves',
  component: () => import(/* webpackChunkName: "waves-views" */'../views/WavesListView.vue'),
  meta: {
    breadcrumbs: {
      text: 'waves.global.waves'
    }
  }
}] as RouteConfig[]
